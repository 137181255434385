
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import Creative from '@/models/Creative'
import { VastMediaFile } from 'vast-client'
import CreativePicker from '@/components/CreativePicker/CreativePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { now } from 'lodash'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import EditableSlot from '@/pages/LineItem/components/EditableSlot.vue'
import CreativeForm from '@/pages/Creative/components/CreativeForm.vue'
import WebMessage from '@/models/WebMessage'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import AssociatedCreative from '@/models/AssociatedCreative'
import moment from 'moment'
import axios from 'axios'
// @ts-ignore
import * as FileSaver from 'file-saver'
import _fields from '../creatives-fields'

@Component({
  components: {
    Widget,
    IconAction,
    CreativePicker,
    FormInput,
    SelectPicker,
    EditableSlot,
    CreativeForm,
    DatePicker,
  },
})
export default class CreativesTable extends ViewModel {
  @Ref() dataTable!: any

  @Prop()
  public advertiser_id!: any

  @Ref() readonly player!: HTMLFormElement

  @Ref() readonly canvas!: HTMLFormElement

  @Prop({ default: true })
  public toggleCrossCreative!: any

  @Prop({ default: true })
  public add_line_item!: any

  @Prop()
  public LineItem!: any

  @Ref()
  public creativePicker!: any

  @Prop({ default: 'line-item' })
  public mode!: string

  public selected_action: any = null

  public show_player_controls: boolean = false

  public cross_advertiser: boolean = false

  public loading_creative: boolean = false

  public loading: boolean = true

  public creative: Creative = new Creative()

  public page: number = 1

  public page_size: number = 100

  public records: number = 0

  public query: string[] = []

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public fields: any = _fields

  public media_file: VastMediaFile | null = null

  public weight_modes: any = [
    {
      value: 'auto',
      name: 'Auto',
    },
    {
      value: 'goal',
      name: 'Goal',
    },
    {
      value: 'percentage',
      name: 'Percentage',
    },
  ]

  public temp_creative: any = {
    weight_mode: 'auto',
    weight_value: 0,
    sequence: 0,
    creative: {
      id: null,
      name: '-',
    },
    selected_option: null,
    start_at: null,
    end_at: null,
    status: 'not saved',
  }

  public temp_creative_collection: any = []

  @Watch('selected_action')
  public selectedOptionChange(val: any) {
    this.temp_creative.selected_option = val
  }

  public currentPage: any = 1

  public sortBy: any = 'created_at'

  public sortDesc: boolean = true

  public filter: string = ''

  public create_new_creative: boolean = false

  public adding_line_item: boolean = false

  public get startOfDay() {
    return moment().startOf('day').toDate()
  }

  public get endOfDay() {
    return moment().endOf('day').toDate()
  }

  public get line_item_adserver() {
    if (!this.LineItem) return false
    return this.LineItem.adserver
  }

  @Watch('create_new_creative')
  public createNewCreative(val: any) {}

  public modal: any = {
    preview: false,
    tag: false,
    create: false,
  }

  @Watch('cross_advertiser')
  public onAdServerChange(val: any) {
    this.temp_creative_collection = []
  }

  public local_creatives: AssociatedCreative[] = []

  public mounted() {
    if (!this.LineItem.order_id) {
      this.selected_action = 'select_creative'
    }
  }

  public created() {
    setTimeout(() => {
      Vue.set(this, 'local_creatives', this.LineItem.associated_creatives)
      this.runActions()
      this.loading = false
    }, 400)
  }

  public mapToOptions(associated_creatives: any) {
    return associated_creatives.map((ac: any) => ({
      name: ac.creative.name,
      value: ac.id,
    }))
  }

  public runActions() {
    let sequence_index = this.fields.findIndex((e: any) => e.key === 'sequence')
    let weight_value_index = this.fields.findIndex((e: any) => e.key === 'weight_value')
    let weight_mode_index = this.fields.findIndex((e: any) => e.key === 'weight_mode')
    let start_at_index = this.fields.findIndex((e: any) => e.key === 'start_at')
    let end_at_index = this.fields.findIndex((e: any) => e.key === 'end_at')

    this.fields[weight_value_index].show = false
    this.fields[sequence_index].show = false

    if (this.LineItem.creative_rotation === 'sequential') {
      this.fields[sequence_index].show = true
      this.fields[weight_value_index].show = false
      this.fields[weight_mode_index].show = false
    }

    if (this.LineItem.creative_rotation === 'weighted') {
      this.fields[weight_value_index].show = true
      this.fields[weight_mode_index].show = true
      this.fields[sequence_index].show = false
    }

    if (this.cross_advertiser) {
      this.fields[weight_value_index].show = false
      this.fields[sequence_index].show = false
    }

    if (this.$route.name === 'order-overview') {
      this.fields[weight_mode_index].show = false
      this.fields[start_at_index].show = false
      this.fields[end_at_index].show = false
    } else {
      this.fields[weight_mode_index].show = true
      this.fields[start_at_index].show = true
      this.fields[end_at_index].show = true
      this.blockSaveBtn()
    }
  }

  public findTableField(key_name: string) {
    let field = this.fields.findIndex((e: any) => e.key === 'type')
  }

  public get show_fields(): Array<Creative> {
    return this.fields.filter((field: any) => field.show)
  }

  public removeCreative(c: any) {
    let message = 'Are you sure you want to remove this creative?'

    if (this.mode === 'order') {
      message = 'Are you sure you want to remove this creative from this order? This will also remove the creative from the line items.'
    }

    WebMessage.confirm(message, 'Remove Creative', {
      okTitle: 'Remove',
    }).then((r: any) => {
      if (r) {
        if (!c.id) {
          this.local_creatives = this.local_creatives.filter(
            (e: any) => e.creative.id !== c.creative.id,
          )
          return
        }
        this.LineItem.remove_creatives.push(c.creative.id)

        WebMessage.warning('Please save the Line items to remove the creative')
      }
    })
  }

  public cancelRemove(c: any) {
    this.LineItem.remove_creatives = this.LineItem.remove_creatives.filter(
      (e: any) => e !== c.creative.id,
    )
  }

  public async previewCreative(creative: any) {
    this.creative = creative.creative
    this.media_file = null
    this.loading_creative = true
    this.creative.preview().then(async (r: VastMediaFile) => {
      if (r) {
        this.media_file = r
        axios.get(r.fileURL, { responseType: 'blob' }).then(response => {
          this.media_file.media = new File([response.data], 'x.mp4', response.type)
          this.media_file.localUrl = window.URL.createObjectURL(this.media_file.media)
          this.loading_creative = false
        })
        this.modal.preview = true
      }
    })
  }

  public cancelNewCreative(close: boolean = false) {
    this.modal.create = close
    this.temp_creative = {
      weight_mode: 'auto',
      weight_value: 0,
      sequence: 0,
      creative: {
        id: null,
        name: '-',
      },
      start_at: null,
      end_at: null,
      selected_option: this.selected_action,
      status: 'not saved',
    }
  }

  public addCreative() {
    this.modal.create = true
  }

  public updatePicker(data: any) {
    Vue.set(this.creativePicker, 'options', [...this.creativePicker.options, data.option])
    Vue.set(this.creativePicker, 'local_value', data.selected)
  }

  public addCreativeToLineItem() {
    this.adding_line_item = true

    for (let index = 0; index < this.temp_creative_collection.length; index++) {
      const id: string = this.temp_creative_collection[index]

      Creative.get(id).then((c: Creative | null) => {
        if (c) {
          let temp: AssociatedCreative = AssociatedCreative.toObject({
            weight_mode: 'auto',
            weight_value: 0,
            sequence: 0,
            creative_id: id,
            creative: {
              ...c,
              id,
              name: c.name,
              duration: c.duration,
            },
            selected_option: null,
            status: 'not saved',
          })

          this.local_creatives.push(temp)
          if (index === this.temp_creative_collection.length - 1) {
            this.$emit('input', this.local_creatives)
            this.adding_line_item = false
            this.cancelNewCreative()
          }
        }
      })
    }
  }

  public openCreativeSideBar() {
    this.modal.create = false
    this.create_new_creative = true

    this.$emit('modalOpen', true)
  }

  public requiredDateIfSelected(index: number, item: any, property: string) {
    if (property === 'end_at') property = 'start_at'
    else property = 'end_at'
    // @ts-ignore
    let component = this.$refs[`${property}_${index}`]

    if (component && !item[property]) {
      // @ts-ignore
      component.$refs[`date_picker_${component.uuid}`].$refs.input.focus()
    }

    setTimeout(() => {
      this.blockSaveBtn()
    }, 500)
  }

  public blockSaveBtn() {
    if (this.$route.name === 'order-overview') return
    let err = 0

    this.local_creatives.forEach((creative, index) => {
      if (creative.start_at && !creative.end_at) {
        err++
      }
      if (!creative.start_at && creative.end_at) {
        err++
      }
    })

    let saveBtn: any = document.getElementById('navigate-next')

    if (!saveBtn) return

    if (err > 0) {
      saveBtn.setAttribute('disabled', true)
    } else {
      saveBtn.removeAttribute('disabled')
    }
  }

  public captureVideoFrame(e: any) {
    e.preventDefault()
    this.player.pause()
    this.canvas.width = this.player.videoWidth
    this.canvas.height = this.player.videoHeight
    this.canvas
      .getContext('2d')
      .drawImage(this.player, 0, 0, this.player.videoWidth, this.player.videoHeight)

    this.canvas.toBlob(blob => {
      FileSaver.saveAs(blob, `${this.creative.name}.preview.png`)
    })
  }
}
